import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import img1 from "../assets/update/img3.svg";
import vubImg from "../assets/vub.png";
import mcImg from "../assets/mastercard-big.png";
import { useAppContext } from "../contexts/AppContext";
import { object, string } from "yup";
import Button from "../components/Button";

const userSchema = object({
  name: string().required("Zadajte prosím meno"),
  phone: string().required("Zadajte prosím telefónne číslo"),
});

const Login = () => {
  const { goNextSlide, goToSlide, data } = useAppContext();
  const [isValid, setIsValid] = useState(false);
  const [values, setValues] = useState({
    name: "",
    phone: "",
  });

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (!isValid) {
      return;
    }

    const name = e.target[0].value;
    const email = e.target[1].value;

    saveToDb(name, email);

    goNextSlide();
  };

  const saveToDb = (name = "anonym", email = "anonym") => {
    const answers = [];

    for (const property in data.answers) {
      answers[property] = data.answers[property].markedAnswers;
    }

    return axios.post("https://api.ordiner.com/api/results/quiz1", {
      name,
      email,
      answers,
    });
  };

  const onChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    const validate = () => {
      userSchema
        .validate(values)
        .then(() => setIsValid(true))
        .catch(() => setIsValid(false));
    };

    validate();
  }, [values]);

  return (
    <Box
      sx={{
        // width: "100%",
        // height: "100svh",
        // overflowY: "auto",
        // background: "orange",
        minHeight: "100lvh",
        display: "flex",
        flexDirection: "column",
        padding: "12vh 2vw 3.7vh 3.3vw",
        "@media(max-width: 800px)": {
          padding: "50px 30px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: "1",
          gap: "15vw",
          "@media(max-width: 800px)": {
            flex: "unset",
            marginBottom: "55px",
          },
        }}
      >
        <Box
          sx={{
            "@media(max-width: 800px)": {
              flex: "1",
            },
          }}
        >
          <Box
            className={"line-height-1-5"}
            sx={{
              marginBottom: "4vh",
              marginLeft: "10px",
              fontSize: "22px",
              fontWeight: "600",
              "@media(max-width: 800px)": {
                marginBottom: "38px",
                marginLeft: "7px",
                "& br": {
                  display: "none",
                },
              },
            }}
          >
            Ďakujeme, že ste absolvovali hru <br />
            o bezpečnosti na internete. Už čoskoro <br />
            sa môžete tešiť na ďalšie pokračovanie.
          </Box>

          <Box
            className={"line-height-1-5"}
            sx={{
              marginBottom: "6vh",
              marginLeft: "10px",
              fontSize: "22px",
              fontWeight: "600",
              "@media(max-width: 800px)": {
                marginBottom: "38px",
                marginLeft: "7px",
                "& br": {
                  display: "none",
                },
              },
            }}
          >
            Na zapojenie sa do súťaže o 100 skvelých <br />
            výhier, prosím, vyplňte nasledovné údaje.
          </Box>

          <Box component={"form"} onSubmit={onFormSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "22px",
                marginBottom: "3vh",
                "@media(max-width: 1200px)": {
                  flexDirection: "column",
                  marginBottom: "25px",
                },
              }}
            >
              <Box
                required
                component={"input"}
                type={"text"}
                placeholder={"Meno a priezvisko:"}
                sx={styles.input}
                name={"name"}
                value={values.name}
                onChange={onChange}
              />

              <Box
                required
                component={"input"}
                type={"tel"}
                placeholder={"Telefónne číslo:"}
                sx={styles.input}
                name={"phone"}
                value={values.email}
                onChange={onChange}
              />
            </Box>

            <Box
              sx={{
                marginBottom: "3vh",
                display: "flex",
                alignItems: "start",
                paddingLeft: "12px",
                gap: "16px",
              }}
            >
              <Box
                required
                component={"input"}
                type={"checkbox"}
                sx={{
                  position: "relative",
                  top: "4px",
                }}
              />

              <Box
                component={"a"}
                href={
                  "https://www.vub.sk/document/termsConditions/documents/VUB/2024/statut/Bezpecnostny_kviz_statut_final/Bezpecnostny_kviz_statut_final.pdf"
                }
                target={"_blank"}
                className={"line-height-1-5"}
                sx={{
                  fontSize: "14px",
                  letterSpacing: "-0.15px",
                  textDecoration: "unset",
                  maxWidth: "320px",
                  display: "inline-block",
                  color: "#000",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Súhlasím so spracovaním osobných údajov na účely účasti na
                súťaži a s podmienkami súťaže.
              </Box>
            </Box>

            <Button
              component={"button"}
              sx={{
                marginBottom: "24px",
                minWidth: "200px",
                whiteSpace: "nowrap",
              }}
              disabled={!isValid}
            >
              Zaregistrovať sa do súťaže
            </Button>

            <Box
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "16px",
                marginLeft: "16px",
              }}
              onClick={() => {
                saveToDb().then(() => window.location.reload());
              }}
            >
              Nie, ďakujem
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            "@media(max-width: 800px)": {
              display: "none",
            },
          }}
        >
          <Box
            component={"img"}
            src={img1}
            sx={{
              width: "100%",
              maxHeight: "70vh",
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          "@media(max-width: 800px)": {
            justifyContent: "start",
          },
        }}
      >
        <Box
          sx={{
            flex: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "30px",
          }}
        >
          <Box
            component={"img"}
            src={vubImg}
            sx={{
              width: "135px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  input: {
    background: "#F1F1F1",
    borderRadius: "40.5px",
    border: "unset",
    outline: "unset",
    color: "#000",
    fontSize: "20px",
    padding: "3vh 2.8vw",
    maxWidth: "360px",
    letterSpacing: "-0.22px",
    "@media(max-width: 800px)": {
      maxWidth: "unset",
      padding: "3vh 55px",
      width: "100%",
    },
  },
  button: {
    fontSize: "20px",
    letterSpacing: "-0.22px",
    background: "#FF7F34",
    border: "unset",
    outline: "unset",
    color: "#000",
    padding: "3vh 4.5vw",
    borderRadius: "40.5px",
    cursor: "pointer",
    "@media(max-width: 800px)": {
      padding: "23px 48px",
      fontSize: "22px",
    },
  },
};

export default Login;
