import React from "react";
import { Box } from "@mui/material";
import img1 from "../assets/update/karty1.png";
import vubImg from "../assets/vub.png";
import kartyImg from "../assets/update/karty.png";
import { useAppContext } from "../contexts/AppContext";
import Button from "../components/Button";
import ruka1Img from "../assets/update/ruka1.png";
import ruka2Img from "../assets/update/ruka2.png";
import ruka3Img from "../assets/update/ruka3.png";
import { keyframes } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";

const wave2 = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(5%);
  }
`;

const Home = () => {
  const { goNextSlide } = useAppContext();

  const isMobile = useMediaQuery("(max-width:800px)");

  return (
    <Box
      sx={{
        minHeight: "100lvh",
        display: "flex",
        flexDirection: "column",
        padding: "10vh 2.5vw 3.7vh 5vw",
        "@media(max-width: 800px)": {
          padding: "50px 30px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: "1",
          gap: "15vw",
          "@media(max-width: 800px)": {
            flex: "unset",
            marginBottom: "55px",
            flexDirection: " column",
            gap: "0",
          },
        }}
      >
        <Box
          sx={{
            "@media(max-width: 800px)": {
              flex: "1",
            },
          }}
        >
          <Box
            className={"font font-57"}
            sx={{
              marginBottom: "5vh",
              marginLeft: "10px",
              fontWeight: "600",
              "@media(max-width: 800px)": {
                marginBottom: "38px",
                marginLeft: "7px",
              },
            }}
          >
            Nenechajte <br /> sa nachytať!
          </Box>

          <Box
            className={"font font-25 medium line-height-1-4"}
            sx={{
              marginBottom: "3vh",
              maxWidth: "480px",
              marginLeft: "10px",
              "@media(max-width: 800px)": {
                marginBottom: "38px",
                marginLeft: "7px",
              },
            }}
          >
            Vitajte v kvízovej časti Nenechajte sa nachytať! Zábavnou formou si
            tu môžete otestovať vaše vedomosti a získať užitočné tipy, ako
            chrániť vaše peniaze, osobné údaje a tiež vašu online bezpečnosť.
          </Box>

          <Box
            className={"font font-25 medium line-height-1-4"}
            sx={{
              marginBottom: "9vh",
              maxWidth: "490px",
              marginLeft: "10px",
              "@media(max-width: 800px)": {
                marginBottom: "0",
                marginLeft: "7px",
              },
            }}
          >
            Po dokončení kvízu sa môžete zapojiť do súťaže o 100 skvelých cien!
            🎁 Poďte na to!
          </Box>

          <Button
            sx={{
              "@media(max-width: 800px)": {
                display: "none",
              },
            }}
            onClick={goNextSlide}
          >
            Spustiť kvíz
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isMobile ? (
            <Box
              component={"img"}
              src={img1}
              sx={{
                width: "100%",
                maxHeight: "70vh",
              }}
            />
          ) : (
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                component={"img"}
                src={kartyImg}
                sx={{
                  width: "100%",
                  maxHeight: "58vh",
                  position: "relative",
                  zIndex: "1",
                }}
              />

              <Box
                component={"img"}
                src={ruka1Img}
                sx={{
                  position: "absolute",
                  left: "94%",
                  top: "50%",
                  width: "14%",
                  animation: `${wave2} 4s ease-in-out infinite`,
                  animationDirection: "alternate",
                }}
              />

              <Box
                component={"img"}
                src={ruka2Img}
                sx={{
                  position: "absolute",
                  left: "5%",
                  top: "-20%",
                  width: "25%",
                  zIndex: "2",
                  animation: `${wave2} 4s ease-in-out infinite`,
                  animationDirection: "alternate",
                }}
              />

              <Box
                component={"img"}
                src={ruka3Img}
                sx={{
                  position: "absolute",
                  right: "15%",
                  bottom: "-5%",
                  width: "34%",
                  zIndex: "2",
                  animation: `${wave2} 4s ease-in-out infinite`,
                  animationDirection: "alternate",
                }}
              />
            </Box>
          )}
        </Box>

        <Button
          disableSound
          sx={{
            margin: "0 auto",
            display: "none",
            marginTop: "20px",
            width: "240px",
          }}
          sxMobile={{
            display: "flex",
          }}
          onClick={goNextSlide}
        >
          Spustiť kvíz
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          "@media(max-width: 800px)": {
            justifyContent: "start",
            display: "none",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginBottom: "12px",
              gap: "24px",
              "@media(max-width: 800px)": {
                justifyContent: "start",
              },
            }}
          >
            <Box
              component={"img"}
              src={vubImg}
              sx={{
                width: "135px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
