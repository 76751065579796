import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useAppContext } from "../contexts/AppContext";
import { annualCash, getHackerEmotion } from "../consts/consts";
import confetti1 from "../assets/confetti1.png";
import confetti2 from "../assets/confetti2.png";
import confetti3 from "../assets/confetti3.png";
import confetti4 from "../assets/confetti4.png";
import confetti5 from "../assets/confetti5.png";
import confetti6 from "../assets/confetti6.png";
import confetti7 from "../assets/confetti7.png";
import confetti8 from "../assets/confetti8.png";
import confetti9 from "../assets/confetti9.png";
import confetti10 from "../assets/confetti10.png";
import confetti11 from "../assets/confetti11.png";
import confetti12 from "../assets/confetti12.png";
import confetti13 from "../assets/confetti13.png";
import confetti14 from "../assets/confetti14.png";
import confetti15 from "../assets/confetti15.png";
import confetti16 from "../assets/confetti16.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "../components/Button";

const Rating = () => {
  const { data, goNextSlide, activeSlide, isSavedToDb } = useAppContext();

  const cashString = String(data.cash);

  const isActive = activeSlide === 29;

  const isMobile = useMediaQuery("(max-width:800px)");

  const getText = () => {
    if (data.cash === 8000) {
      return (
        <>
          Výborne 🎉, ochránili ste všetky vaše <br />
          peniaze! Len tak ľahko vás nejaký hacker <br /> nedostane! 🤟
        </>
      );
    }

    if (data.cash > 4800) {
      return (
        <>
          Pozor, prišli ste o časť vašich peňazí! Hacker <br />
          vás dostal. Dajte si pozor na to, s kým a <br />
          kedy zdieľate osobné informácie. Veríme, <br />
          že už sa nenecháte nachytať! 💪
        </>
      );
    }

    return (
      <>
        Ách, niééé! 😭 Hacker vás poriadne obral. <br />
        Dajte pozor na to, s kým a kedy zdieľate <br />
        osobné informácie. Veríme, že už sa <br />
        nenecháte nachytať! 💪
      </>
    );
  };

  useEffect(() => {
    if (!isActive) {
      return;
    }

    if (data.cash !== annualCash) {
      return;
    }

    window.confetti({
      spread: 360,
      ticks: 200,
      gravity: 1,
      decay: 0.94,
      startVelocity: 40,
      particleCount: 150,
      scalar: 6,
      shapes: ["image"],
      shapeOptions: {
        image: [
          {
            src: confetti1,
            width: 137,
            height: 114,
          },
          {
            src: confetti2,
            width: 144,
            height: 139,
          },
          {
            src: confetti3,
            width: 139,
            height: 119,
          },
          {
            src: confetti4,
            width: 137,
            height: 144,
          },
          {
            src: confetti5,
            width: 133,
            height: 108,
          },
          {
            src: confetti6,
            width: 80,
            height: 81,
          },
          {
            src: confetti7,
            width: 65,
            height: 70,
          },
          {
            src: confetti8,
            width: 67,
            height: 65,
          },
          {
            src: confetti9,
            width: 87,
            height: 87,
          },
          {
            src: confetti10,
            width: 53,
            height: 59,
          },
          {
            src: confetti11,
            width: 99,
            height: 79,
          },
          {
            src: confetti12,
            width: 99,
            height: 79,
          },
          {
            src: confetti13,
            width: 147,
            height: 157,
          },
          {
            src: confetti14,
            width: 60,
            height: 51,
          },
          {
            src: confetti15,
            width: 51,
            height: 45,
          },
          {
            src: confetti16,
            width: 134,
            height: 120,
          },
        ],
      },
    });
  }, [data.cash, isActive]);

  return (
    <Box
      sx={{
        // width: "100%",
        // height: "100svh",
        // overflowY: "auto",
        minHeight: "100lvh",
        background: "linear-gradient(180deg, #FF7F34 41.5%, #E76012 100%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "2vh 3vw",
        "@media(max-width: 800px)": {
          padding: "25px 30px 100px 30px",
        },
      }}
    >
      <Box
        className={"font font-50 medium"}
        sx={{
          marginBottom: "3vh",
          lineHeight: "1.2",
          letterSpacing: "-0.66px",
          textAlign: "center",
        }}
      >
        Celková <br /> úspešnosť
      </Box>

      <Box
        className={"font font-30 medium"}
        sx={{
          letterSpacing: "-0.66px",
          textAlign: "center",
          marginBottom: "0vh",
        }}
      >
        Peniaze, ktoré vám ostali:
      </Box>

      <Box
        sx={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          className={"font font-80 medium"}
          sx={{
            letterSpacing: "-1.1px",
            marginBottom: "20px",
          }}
        >
          {cashString[0]} {cashString.slice(1, cashString.length)} €
        </Box>

        {data.cash !== annualCash && (
          <Box
            sx={{
              marginBottom: "20px",
            }}
          >
            <Box
              component={"img"}
              src={getHackerEmotion(data.cash, "black")}
              sx={{
                maxWidth: "155px",
                "@media(max-width: 800px)": {
                  maxWidth: "152px",
                },
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        className={"font font-30 line-height-1-5"}
        sx={{
          textAlign: "center",
          maxWidth: "800px",
          "@media(max-width: 800px)": {
            marginBottom: "20px",
            "& br": {
              display: "none",
            },
          },
        }}
      >
        {getText()}
      </Box>

      <Button
        type={"white"}
        sx={{
          minWidth: "340px",
          marginTop: "40px",
        }}
        sxMobile={{
          position: "absolute",
          left: "0",
          bottom: "0",
          width: "100%",
          height: "74px",
          maxWidth: "unset",
          fontSize: "27px",
        }}
        onClick={goNextSlide}
      >
        Ďakujeme
      </Button>
    </Box>
  );
};

export default Rating;
